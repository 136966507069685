<template>
  <v-card style="height: 500px">
    <demo-header
      :icon="'medexpert-video'"
      :title="'Видео-инструкции'"
      :progress="totalPercent"
      :pageName="pageName"
    ></demo-header>
    <v-divider></v-divider>
    <v-list style="max-height: 415px" class="overflow-y-auto">
      <v-list-item v-for="(item, i) in dataSource.HelpVideos" :key="item.Id">
        <div
          class="tour-manager__page"
          v-delayAddClass="{
            delay: 150 * (i + 1),
            class: 'tour-manager__page-done',
          }"
          :class="{ 'tour-manager__page-notdone': !item.Done }"
          @click="
            dialog = true;
            selectItem = item;
          "
        >
          <div class="tour-manager__page-wrap">
            <div class="tour-manager__page-icon">
              <m-icon icon="medexpert-video" size="32" large color="#00c880" />
            </div>
            <div class="tour-manager__page-info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="tour-manager__page-title"
                    >{{ item.Name }}
                  </span>
                </template>
                <span>{{ item.Name }} </span>
              </v-tooltip>

              <span class="tour-manager__page-stage"
                >{{ Math.floor(item.Duration / 60) }} мин.
                {{ item.Duration % 60 }} сек.</span
              >
            </div>
          </div>

          <span class="tour-manager__page-back">
            <m-icon icon="mdi-arrow-right" color="primary"></m-icon>
          </span>
        </div>
      </v-list-item>
    </v-list>
    <video-popup v-if="selectItem" v-model="dialog" :data="selectItem" />
  </v-card>
</template>
<script>
import tourApi from "../../api/tourApi";
export default {
  name: "demoMenu-tab2",
  components: {
    demoHeader: () => import("../demoHeader"),
    videoPopup: () => import("../videoPopup.vue"),
  },
  props: {
    pageName: {
      type: String,
      default: "Страница",
    },
  },
  data() {
    return { dialog: false, selectItem: null, dataSource: { HelpVideos: [] } };
  },
  computed: {
    totalPercent() {
      return this.dataSource && this.dataSource.NumPerformed > 0
        ? Math.floor(
            (this.dataSource.NumPerformed / this.dataSource.TotalNum) * 100
          )
        : 0;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { data } = await tourApi.help.video.get(
        this.$route.name,
        this.$route.query?.type,
        this.$route.params?.type,
        this.$route.params?.objectId
      );
      this.dataSource = data;
    },
  },
};
</script>
<style lang="scss"></style>
