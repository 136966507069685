var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"height":"500px"}},[_c('demo-header',{attrs:{"icon":'medexpert-video',"title":'Видео-инструкции',"progress":_vm.totalPercent,"pageName":_vm.pageName}}),_c('v-divider'),_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"415px"}},_vm._l((_vm.dataSource.HelpVideos),function(item,i){return _c('v-list-item',{key:item.Id},[_c('div',{directives:[{name:"delayAddClass",rawName:"v-delayAddClass",value:({
          delay: 150 * (i + 1),
          class: 'tour-manager__page-done',
        }),expression:"{\n          delay: 150 * (i + 1),\n          class: 'tour-manager__page-done',\n        }"}],staticClass:"tour-manager__page",class:{ 'tour-manager__page-notdone': !item.Done },on:{"click":function($event){_vm.dialog = true;
          _vm.selectItem = item;}}},[_c('div',{staticClass:"tour-manager__page-wrap"},[_c('div',{staticClass:"tour-manager__page-icon"},[_c('m-icon',{attrs:{"icon":"medexpert-video","size":"32","large":"","color":"#00c880"}})],1),_c('div',{staticClass:"tour-manager__page-info"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tour-manager__page-title"},'span',attrs,false),on),[_vm._v(_vm._s(item.Name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Name)+" ")])]),_c('span',{staticClass:"tour-manager__page-stage"},[_vm._v(_vm._s(Math.floor(item.Duration / 60))+" мин. "+_vm._s(item.Duration % 60)+" сек.")])],1)]),_c('span',{staticClass:"tour-manager__page-back"},[_c('m-icon',{attrs:{"icon":"mdi-arrow-right","color":"primary"}})],1)])])}),1),(_vm.selectItem)?_c('video-popup',{attrs:{"data":_vm.selectItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }